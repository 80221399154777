export const global = {
	init: function () {
		// this.changeUrl();
		this.showHideMenu();
		this.initBackgroundVideo();
		this.createMap();
		this.initializeGallery();

		let resizeTimer;
		$(window).on('resize', _ => {
			clearTimeout(resizeTimer);

			resizeTimer = setTimeout(_ => {
				this.clearMenuPosition();
			}, 250);
		});
	},

	showHideMenu: function () {
		const hamburgerBtn = document.querySelector('.menu__hamburger-container');
		const hamburgerLines = hamburgerBtn.querySelectorAll('.menu__hamburger-line');
		const menuList = document.querySelector('.menu__list-wrapper');
		const blackout = document.querySelector('.blackout');

		hamburgerBtn.addEventListener('click', _ => {
			if (menuList.classList.contains('active')) {
				menuList.classList.remove('active');
				blackout.classList.remove('blackout--visible');
				hamburgerLines.forEach(el => el.classList.remove('menu__hamburger-line--active'));
				return;
			}
			menuList.classList.add('active');
			blackout.classList.add('blackout--visible');
			hamburgerLines.forEach(el => el.classList.add('menu__hamburger-line--active'));
		})
	},

	clearMenuPosition: function () {
		const menuList = document.querySelector('.menu__list-wrapper');

		if (window.innerWidth > 767 && menuList.classList.contains('active')) menuList.classList.remove('active');
	},

	initBackgroundVideo: function () {
		const video = document.querySelector('.banner__video');

		$(video).bgVideo({fullScreen: true});
	},

	createMap: function () {
		const mapElement = document.querySelector('#map');
		if (!mapElement) return;
		let map = L.map('map').setView([52.814347, 17.19012079999993], 10);

		L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
		}).addTo(map);

		L.marker([52.814347, 17.19012079999993]).addTo(map).bindPopup('ul. Zimowa 3, 62-100 Wągrowiec');
		L.marker([52.857290, 17.153250]).addTo(map).bindPopup('Nowe 25, 62-100 Wągrowiec');;

		return map;
	},

	initializeGallery: function () {
		$('.gallery__content').magnificPopup({
			delegate: 'a',
			type: 'image',
			gallery: {
				enabled: true
			}
		});
	},

	changeUrl: function () {
		let url = window.location.href;
		url = url.split('.html')[0];
		window.history.replaceState( null, null, url );
	}
};